.App {
  min-width: 570px;
}

.header {
  margin-bottom: 50px;
  margin: auto;
  text-align: center
}

.header img {
  /* width: 100%; */
  max-height: 70vh;
  max-width: 100%;
}

.main {
  margin: 20px 50px;
  max-width: 1150px;
  margin: 0 auto;
}

.row {
  margin: 15px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

@media (max-width: 800px) {
  .row {
    flex-direction: column;
  }

  .booking > a {
    margin: 10px 0;
  }
}

.images {
  display: grid;
  grid-template-columns: repeat(auto-fill, 570px);
  place-content: center;
  gap: 10px;
  max-width: 1150px;
  margin: 0 auto;
  margin-bottom: 40px;
}

.booking {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  justify-content: space-around;
}

.image img {
  width: 100%;
  border-radius: 30px;
}

.blurb {
  /* width: 600px; */
  line-height: 2em;
  text-align: center;
}


.spacer {
  width: 100px;
  height: 15px;
}

.booking a {
  background-color: rgb(41, 135, 184);
  text-align: center;
  color: rgb(191, 203, 232);
}

.contact a {
  background-color: rgb(191, 203, 232);
  text-align: center;
  /* color: rgb(41, 135, 184);
  font-weight: bolder; */
}

.something {
  color: rgb(191, 203, 232);
  background-color: rgb(41, 135, 184);
}